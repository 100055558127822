// Dependencies
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { PhotoSwipe } from 'react-photoswipe'

// Styles
import { FotosWrapper } from './Fotos.styled'
import '../../assets/photoswipe/photoswipe.css'

// Components
import { previewSVG } from '../../assets/icons'

class ModalPortal extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.modalRoot = document.getElementById('modal-root')
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

const Gallery = ({ gallery, id, compact = false, size = -1 }) => {
  const LIMIT = 8
  const [limit, setLimit] = useState(LIMIT)

  let [isOpen, setIsOpen] = useState(false)
  const baseOptions = {
    closeOnScroll: false,
    history: false,
    preload: [3, 3],
    closeEl: true,
    captionEl: true,
    fullscreenEl: true,
    zoomEl: true,
    shareEl: false,
    counterEl: true,
    arrowEl: true,
    preloaderEl: true,
  }
  let [options, setOptions] = useState({
    index: id || 1,
    ...baseOptions,
  })

  let items = gallery.galleryImages.map((item) => {
    return {
      src: item.fluid.src,
      w: 1600,
      h: 1600 / item.fluid.aspectRatio,
    }
  })

  const handleClose = () => {
    setIsOpen(false)
  }

  const openGallery = (index) => {
    setOptions({
      index,
      ...baseOptions,
    })
    setIsOpen(true)
  }

  return (
    <>
      <FotosWrapper className={compact ? 'compact' : ''}>
        <h3>{gallery.galleryName}</h3>
        <div>
          {gallery.galleryThumbs
            .slice(0, compact && size !== -1 ? size : limit)
            .map((image, i) => {
              return (
                <a
                  onClick={() => openGallery(i)}
                  key={`${id}_foto_${i}`}
                  className="foto"
                >
                  <Img fluid={image.fluid} />
                  <div dangerouslySetInnerHTML={{ __html: previewSVG }} />
                </a>
              )
            })}
        </div>
        {!compact && limit < gallery.galleryImages.length && (
          <button className="button" onClick={() => setLimit(limit + LIMIT)}>
            <span>Mostrar Mais</span>
          </button>
        )}
      </FotosWrapper>
      <ModalPortal>
        <PhotoSwipe
          isOpen={isOpen}
          items={items}
          options={options}
          onClose={handleClose}
        />
      </ModalPortal>
    </>
  )
}

// Components PropTypes
Gallery.propTypes = {
  gallery: PropTypes.shape({
    galleryName: PropTypes.string,
    galleryImages: PropTypes.arrayOf(
      PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
          srcSet: PropTypes.string,
          srcWebp: PropTypes.string,
          srcSetWebp: PropTypes.string,
          sizes: PropTypes.string,
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
        }),
      })
    ),
  }),
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
}

export default Gallery
