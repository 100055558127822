// Dependencies
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

// Components
import Gallery from './Gallery'

// Context & Helpers
import { Context as MicroCopyContext } from '../../context/microcopyContext'
import { getRandom, getRandoms } from '../../helpers/utils'
import { getMicroCopy } from '../../helpers/microcopy'

const Fotos = ({ galleries, widget = false, size = 2 }) => {
  const { state } = useContext(MicroCopyContext)
  return (
    <div className="content">
      {(widget ? [getRandom(galleries)] : galleries).map((gallery, i) => {
        return (
          <Gallery
            gallery={{
              ...gallery,
              galleryImages: gallery.galleryImages,
            }}
            id={`gallery_${i}`}
            key={`gallery_${i}`}
            compact={widget}
            size={widget ? size : -1}
          />
        )
      })}
      {widget && (
        <AniLink
          paintDrip
          hex="#ffe5cb"
          duration={1}
          className="button"
          to={'/fotos'}
        >
          <span>
            {getMicroCopy({ key: `ctas.seePhotos`, data: state?.value })}
          </span>
        </AniLink>
      )}
    </div>
  )
}

// Components PropTypes
Fotos.propTypes = {
  sectionTitle: PropTypes.string,
  sectionCopy: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  galleries: PropTypes.arrayOf(
    PropTypes.shape({
      galleryName: PropTypes.string,
      galleryImages: PropTypes.arrayOf(
        PropTypes.shape({
          fluid: PropTypes.shape({
            src: PropTypes.string,
            srcSet: PropTypes.string,
            srcWebp: PropTypes.string,
            srcSetWebp: PropTypes.string,
            sizes: PropTypes.string,
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
          }),
        })
      ),
    })
  ),
}
Fotos.defaultProps = {
  sectionTitle: null,
  sectionCopy: null,
}

export default Fotos
