// Dependencies
import styled from 'styled-components'

// Helpers
import { hexToRgb } from '../../helpers/utils'

// Constants
import { colors } from '../../constants/colors'
import {
  playfairDisplay,
  type30,
  type20,
  type14,
  type25,
} from '../../constants/fonts'
import { sizesMax, maxWidthValue, sizesMin } from '../../constants/breakpoints'
import { transition } from '../../constants/general'

export const FotosWrapper = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: calc(100% / 11 * 11);
  }

  padding-top: 10px;

  &:not(:last-child) {
    padding-bottom: 30px;
  }

  &.compact {
    padding-bottom: 0 !important;
  }

  &.compact .foto {
    width: calc(100% / 11 * 5) !important;
    margin-right: 0 !important;
    margin-right: calc(
      calc(50% - calc(100% / 11 * 5)) + calc(50% - calc(100% / 11 * 5))
    ) !important;
    &:nth-child(2n) {
      margin-right: 0 !important;
    }

    img,
    .gatsby-image-wrapper {
      height: 30vw !important;

      @media screen and (min-width: ${sizesMin.mobile}) {
        height: 15vw !important;
      }

      @media screen and (min-width: ${sizesMin.laptop}) {
        height: 200px !important;
      }
    }
  }

  .foto {
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;

    @media screen and (min-width: 0px) and (max-width: ${sizesMax.tablet}) {
      width: calc(100% / 11 * 5);
      margin-right: calc(
        calc(50% - calc(100% / 11 * 5)) + calc(50% - calc(100% / 11 * 5))
      );
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media screen and (min-width: ${sizesMin.tablet}) and (max-width: ${sizesMax.laptop}) {
      width: calc(100% / 11 * 3);
      margin-right: calc(
        calc(calc(100% / 3) - calc(100% / 11 * 3)) +
          calc(calc(calc(100% / 3) - calc(100% / 11 * 3)) / 2)
      );
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @media screen and (min-width: ${sizesMin.laptop}) {
      width: calc(100% / 12 * 2.6);
      margin-right: calc(
        calc(25% - calc(100% / 12 * 2.6)) +
          calc(calc(25% - calc(100% / 12 * 2.6)) / 3)
      );
      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      left: 0;
      top: 0;
      background: rgba(${hexToRgb(colors.black)}, 0);
      ${transition}
      border: solid 5px rgba(${hexToRgb(colors.white)}, 0);
    }

    &:hover {
      &::after {
        background: rgba(${hexToRgb(colors.black)}, 0) !important;
        border: solid 5px rgba(${hexToRgb(colors.white)}, 1);
      }
    }

    svg {
      background: ${colors.white};
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -20px 0 0 -20px;
      width: 40px;
      height: 40px;
      opacity: 0;
      padding: 0px;
      ${transition}

      circle {
        fill: rgba(${hexToRgb(colors.cream)}, 0);
        ${transition}
      }
      path {
        fill: rgba(${hexToRgb(colors.cream)}, 1);
        ${transition}
      }
    }
    &:hover {
      svg {
        background: ${colors.white};
        circle {
          fill: rgba(${hexToRgb(colors.cream)}, 0);
        }
        path {
          fill: rgba(${hexToRgb(colors.black)}, 1);
        }
        opacity: 1;
      }
    }
  }

  &:hover .foto {
    &::after {
      background: rgba(${hexToRgb(colors.black)}, 0.5);
    }
  }

  img,
  .gatsby-image-wrapper {
    width: 100%;
    height: 30vw;

    @media screen and (min-width: ${sizesMin.mobileL}) {
      height: 30vw;
    }
    @media screen and (min-width: ${sizesMin.tablet}) {
      height: 20vw;
    }
    @media screen and (min-width: ${sizesMin.laptop}) {
      height: 15vw;
    }

    @media screen and (min-width: ${maxWidthValue}) {
      height: 170px;
    }
  }
`
